<template>
  <div>launch</div>
</template>

<script>
export default {
  name: 'Launch',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
