import { login, logout, getInfo } from '@/api/ManagerUser'
import auth from '@/utils/auth'
import router, { constantRoutes, resetRouter } from '@/router/manifest'
import { initRouter } from '@/utils/packMenu'

const getDefaultState = () => {
  return {
    token: auth.getToken(),
    name: '',
    avatar: '',
    menuList: [],
    permission: []
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_MENU_LIST: (state, { routers }) => {
    state.menuList = routers
  },
  SET_PERMISSION: (state, permission) => {
    state.permission = permission
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { userName, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ userName: userName.trim(), password: password })
        .then(response => {
          const { data } = response
          commit('SET_TOKEN', data)
          auth.setToken(data)
          resolve()
        }).catch(error => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          return reject('验证失败, 请先登录.')
        }

        const { name, avatar, permissions } = data

        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_PERMISSION', permissions)

        const addRouters = data.menus.map(initRouter)
        addRouters.push({ path: '*', redirect: '/404', hidden: true })

        addRouters.forEach(route => router.addRoute(route))

        const routers = constantRoutes.concat(addRouters)

        commit('SET_MENU_LIST', { routers })

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        auth.removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      auth.removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

