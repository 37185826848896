<template>
  <div class="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: 'ScrollBoard',
  data() {
    return {
      config: {}
    }
  },
  mounted() {
    this.config = {
      header: ['时间', '测试数据', '数量', '测试数据'],
      data: [
        ['2024-06-01 19:25:00', '测试数据', '5', 'xxxxxxx'],
        ['2024-06-02 17:25:00', '测试数据', '13', 'xxxxxxx'],
        ['2024-06-03 16:25:00', '测试数据', '6', 'xxxxxxx'],
        ['2024-06-04 15:25:00', '测试数据', '2', 'xxxxxxx'],
        ['2024-06-05 14:25:00', '测试数据', '1', 'xxxxxxx'],
        ['2024-06-06 13:25:00', '测试数据', '3', 'xxxxxxx'],
        ['2024-06-07 12:25:00', '测试数据', '4', 'xxxxxxx'],
        ['2024-06-08 11:25:00', '测试数据', '2', 'xxxxxxx'],
        ['2024-06-09 10:25:00', '测试数据', '5', 'xxxxxxx'],
        ['2024-06-10 09:25:00', '测试数据', '3', 'xxxxxxx']
      ],
      index: true,
      columnWidth: [50, 170, 100],
      align: ['center'],
      rowNum: 7,
      headerBGC: '#1981f6',
      headerHeight: 45,
      oddRowBGC: 'rgba(0, 44, 81, 0.8)',
      evenRowBGC: 'rgba(10, 29, 50, 0.8)'
    }
  }
}
</script>

<style lang="less">
.scroll-board {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  margin: 0 20px;
}
</style>
