<template>
  <div id="app">
    <router-view style="width: 100%; height: 100%" />
  </div>
</template>

<script>

// (function() {
//   function resize() {
//     const body = document.getElementsByTagName('body')[0]
//     const html = document.getElementsByTagName('html')[0]
//     const width = html.clientWidth
//     const height = html.clientHeight
//     const max = width > height ? width : height
//     const min = width > height ? height : width
//     body.style.width = max + 'px'
//     body.style.height = min + 'px'
//   }
//   resize()
//   window.addEventListener('resize', resize)
// })()

export default {
  name: 'App'
}
</script>

<style>

/*@media screen and (orientation:portrait) {*/
/*  !*竖屏样式*!*/
/*  body {*/
/*    transform-origin: 0 0;*/
/*    transform: rotateZ(90deg) translateY(-100%);*/
/*  }*/
/*}*/

#app{
  width: 100%;
  height: 100%;
}

.view{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.form-inline{
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  align-self: flex-start;
}

body .el-table::before {
  z-index: inherit;
}

.view{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.table-content{
  width: calc(100% - 20px);
}

.form-inline{
  align-self: flex-start;
}

.pagination{
  margin: 16px;
}

.hidden{
  display: none;
}

</style>
