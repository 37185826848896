import { render, staticRenderFns } from "./RoseChart.vue?vue&type=template&id=84c8d870"
import script from "./RoseChart.vue?vue&type=script&lang=js"
export * from "./RoseChart.vue?vue&type=script&lang=js"
import style0 from "./RoseChart.vue?vue&type=style&index=0&id=84c8d870&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports