import axios from 'axios'
import { MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import auth from '@/utils/auth'

// create an axios instance
const timeout = 60000
const service = axios.create({
  // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send localStorage when cross-domain requests
  timeout: timeout // request timeout
})

let isShowLoading = true

export function hideRequestLoading() {
  isShowLoading = false
}

let loading
// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['token'] = auth.getToken()
    }

    if (isShowLoading) {
      loading = Loading.service({
        text: '加载中',
        body: true
      })
    }
    isShowLoading = true
    return config
  },
  error => {
    hideLoading()
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    hideLoading()

    // 若是返回的类型不为json, 此接口不是普通数据接口
    if (!response.headers['content-type'].includes('application/json')) {
      return response
    }

    if (res.code !== 200) {
      // 508: 无效的token; 512: 其他客户端登录; 514: token失效;
      if (res.code === 508 || res.code === 512 || res.code === 514) {
        MessageBox.confirm('登录失效', '退出登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        }).catch(() => {})
        return Promise.resolve()
      }

      if (res.code === 203) {
        Message({
          message: res.message,
          type: 'warning',
          duration: 2000
        })
        return Promise.reject(res.message)
      }
    }

    return res
  },
  error => {
    let message
    if (error.toString() === 'Error: Network Error') {
      if (window.navigator.onLine) {
        message = '服务器异常，请联系管理员18482103899'
      } else {
        message = '请检查你的网络'
      }
    } else if (error.toString() === `Error: timeout of ${timeout}ms exceeded`) {
      message = '网络太差了'
    } else {
      message = '服务器错误'
    }

    hideLoading()

    Message({
      message: message,
      type: 'error',
      duration: 5000
    })

    return Promise.reject(error)
  }
)

function hideLoading() {
  if (loading) {
    loading.close()
    loading = null
  }
}

export default service
