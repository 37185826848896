<template>
  <div class="ranking-board">
    <div class="ranking-board-title">每日激活设备</div>
    <dv-scroll-ranking-board :config="config" class="ranking-board-content" />
  </div>
</template>

<script>
import dataStatisticsApi from '@/api/DataStatistics'

export default {
  name: 'RankingBoard',
  data() {
    return {
      config: {}
    }
  },
  mounted() {
    this.getDayActiveDevice()
  },
  methods: {
    getDayActiveDevice() {
      dataStatisticsApi.dayActiveDevice().then(response => {
        const data = []
        for (let i = 0; i < response.data.length; i++) {
          data.push({
            name: response.data[i].date,
            value: response.data[i].activeNumber
          })
        }
        this.config = {
          data: data,
          rowNum: 8
        }
      })
    }
  }
}
</script>

<style lang="less">
.ranking-board {
  width: 20%;
  height: 90%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0 30px;

  .ranking-board-title {
    font-weight: bold;
    height: 10%;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .ranking-board-content{
    height: 600px;
  }
}
</style>
