import Layout from '@/layout/index'

export function initRouter(data) {
  const result = { meta: {}, children: [] }

  result.meta.icon = data.menuIcon
  result.meta.title = data.menuName

  result.path = data.path
  result.name = data.menuName

  if (data.type === 0) {
    result.component = Layout
    result.redirect = data.children[0].path
  } else {
    result.component = loadView(data.component)
  }

  if (data.children && data.children.length > 0) {
    for (let i = 0; i < data.children.length; i++) {
      result.children.push(initRouter(data.children[i]))
    }
  }

  return result
}

export const loadView = uri => {
  return (resolve) => require([`@/views${uri}`], resolve)
}

